$tstar-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;
$tstar-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$ano-bold_regular-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$times-font: TimesNewRoman, 'Times New Roman', Times, serif;
$roboto-condensed-bold-font: roboto-condensed-bold, Helvetica, Arial, Sans-Serif;
$roboto-condensed-regular-font: roboto-condensed-regular, Helvetica, Arial, Sans-Serif;
$body-font-size: 12.5px;
$opc_update: true;
$password_strengthen: true;

// Hiding b_functional section in privacy slider
$enable_cookie-img: true;

// 2020 styling refresh.
$cr20: true;

$gnav_update: true;

// 2021 styling refresh.
$cr21: true;

// 2021 styling refresh for SPP.
$cr21_spp: false;